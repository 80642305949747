import menu from '~/static/menu'
import pages from '~/static/varme'
export const state = () => ({
  menu: menu,
  pages: pages.data,
  lang: 'en'
})
export const mutations = {
  SET_MENU(state, menu) {
    state.menu = menu
  },
  setData(state, { storage, key, data }) {
    Vue.set(storage, key, data)
  }
}
