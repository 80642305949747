
import { mapState } from 'vuex'
export default {
	name: 'navigation',
	data() {
    return {
			content: null,
			drawer: false,
			direction: 'rtl',
			title: 'varmepumpe.prisberegning.dk'
		}
  },
	computed: {
    ...mapState(["menu"]),
  }
}
