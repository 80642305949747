
import index from '~/static/index.json'
import waves from '~/static/waves.svg?raw'
import tallWaves from '~/static/wavesTall.svg?raw'
import keyboard from '~/static/keyboard.svg?raw'
import email from '~/static/email.svg?raw'
import phone from '~/static/phone.svg?raw'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
export default {
  name: 'guide',
  data() {
    return {
      index: index.data[0],
      waves,
      keyboard,
      email,
      phone,
      tallWaves
    }
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
}
